import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Nav from "../components/Nav"
import PortfolioNav from "../components/Portfolio"

const PortfolioLayout = styled.div`
  display: flex;
  margin-top: 5rem;
`

const CMSHTML = styled.div`

  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

img {
    max-width: 400px;
}

a {
  text-decoration: none;
  color: #1eaef1;
}

iframe {
  margin-bottom: 3rem;
}

`

const ProjectTemplate = ({ data }) => {
  const content = data.markdownRemark
  return (
    <Layout>
      <Nav page="portfolio" />
      <PortfolioLayout>
      <PortfolioNav>
      </PortfolioNav>
      <div style={{ width: '80%'}}>
        <h2
              style={{
                maxWidth: '400px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '2rem'
             }}
              >{
              content.frontmatter.title}
          </h2>
        <CMSHTML dangerouslySetInnerHTML={{ __html: content.html }} />
      </div>
      </PortfolioLayout>
    </Layout>
  )
}

export default ProjectTemplate

export const ProjectTemplateQuery = graphql`
  query($id: String!) {
    markdownRemark(parent: { id: { eq: $id } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
